import React from "react"
import { parse } from "node-html-parser"

export const formatAnchor = text => {
  const document = parse(text)
  // Handles external links
  const links = document.querySelectorAll(`a`)
  links.forEach(link => {
    link.setAttribute("target", "_blank")
    link.setAttribute("rel", "noopener noreferrer")
  })
  return document.innerHTML
}

export const CreateParagraph = props => {
  const { key, className, content } = props
  if (!content) {
    return null
  }
  return (
    <p
      key={key}
      className={className}
      dangerouslySetInnerHTML={{
        __html: formatAnchor(content),
      }}
    ></p>
  )
}

export const CreateSpan = props => {
  const { key, className, content } = props
  if (!content) {
    return null
  }
  return (
    <span
      key={key}
      className={className}
      dangerouslySetInnerHTML={{
        __html: formatAnchor(content),
      }}
    ></span>
  )
}

export const CreateSpanWithSmall = props => {
  const { key, className, smallClassName, content, smallContent } = props
  if (!content && !smallContent) {
    return null
  }
  return (
    <span
      key={key}
      className={className}
    >
      {content}
      <small
        key={key}
        className={smallClassName}
      >
        {smallContent}
      </small>
    </span>
  )
}
