import React from "react"
import RotationImg from "../../../static/images/rotation.png"

export default function LandScapeWarning() {
  return (
    <div className="screen-warning">
      <div className="media">
        <img
          src={RotationImg}
          className="d-block mx-auto"
          alt="Rotation Icon"
        />
      </div>
      <div className="text-1">Please rotate your device</div>
      <div className="text-2">We support <span className="landscapeMode">landscape</span> <span className="portraitMode">portrait</span>  mode only</div>
    </div>
  )
}
