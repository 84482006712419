import $ from "jquery"
import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { Collapse } from "react-bootstrap"
import {constants} from "../../constants/Global_Contants";

export const RenderMenu = ({ menuItem, setSelectedMenu }) => {
  return (
    <>
      <li key={menuItem.id}>
        <Link
          to={menuItem.path || "#"}
          onMouseOver={e => {
            setSelectedMenu(11)
          }}
          className="is--hover collapsed"
        >
          {menuItem.label}
        </Link>
        <Link to={menuItem.path || "#"} className="is--touch collapsed">
          {menuItem.label}
        </Link>
      </li>
    </>
  )
}

export const RenderCollapsibleMenu = ({
  menuItem,
  index,
  selectedMenu,
  setSelectedMenu,
}) => {
  const renderChildMenu = (menuItem.childItems.nodes || []).map(menu => {
    if(typeof menu.slug !== "undefined"){
    return (
      <li key={menu.id}>
        <Link to={`/${menu.slug}` || "#"}>{menu.label}</Link>
      </li>
    )
    }
  })
  return (
    <>
      <li key={menuItem.id}>
        <Link
          to={menuItem.path}
          onMouseOver={e => {
            setSelectedMenu(index)
            // e.stopPropagation()
            // e.preventDefault()
          }}
          className={`is--hover ${index !== selectedMenu ? "collapsed" : ""}`}
        >
          {menuItem.label}
        </Link>
        <Link
          to={"#"}
          onClick={e => {
            setSelectedMenu(index)
            e.stopPropagation()
            e.preventDefault()
          }}
          className={`is--touch ${index !== selectedMenu ? "collapsed" : ""}`}
        >
          {menuItem.label}
        </Link>
        <Collapse in={index === selectedMenu} timeout={500}>
          <ul
            id={`sub-menu-${index}`}
            className={`nav-secondary  ${index !== selectedMenu ? "collapse" : "show"
              }`}
          >
            {renderChildMenu}
          </ul>
        </Collapse>
      </li>
    </>
  )
}

export function Menu() {
  useEffect(() => {
    if (typeof window !== undefined) {
      if (typeof window !== undefined) {
        let toolTipSelector =
          ".slick-list, .slick-arrow, .articleBackLink, .paper__inner .btn-primary"
        setTimeout(function () {
          $(".slick-list") &&
            $(".slick-list").attr("data-title", "CLICK <br> &amp; DRAG")
        }, 100)

        $(document).on("mousemove", toolTipSelector, function (e) {
          var x = e.clientX
          var y = e.clientY
          var newposX = x - 80
          var newposY = y + 5
          var toolTipText = $(this).attr("data-title")

          $(".tooltipText span").html(toolTipText)
          $(".svg-container").addClass("animateBorder")
          $(".svg-container").css({
            left: newposX,
            top: newposY,
          })
        })
        $(document).on("mouseout", toolTipSelector, function (e) {
          $(".svg-container").removeClass("animateBorder")
        })
        $(document).on("mousemove", ".link, .marker-list li", function (e) {
          $(".svg-container").removeClass("animateBorder")
          e.stopPropagation()
        })
      }
    }
  }, [])

  const {
    allWpMenu: { nodes },
    allWpCategory: { nodes: catNodes },
    allWp: { nodes: footerNodes },
  } = useStaticQuery(
    graphql`
      query {
        allWpMenu(
          filter: {
            slug: { eq: "primary" }
            menuItems: { nodes: { elemMatch: { parentDatabaseId: { eq: 0 } } } }
          }
        ) {
          nodes {
            name
            menuItems {
              nodes {
                path
                label
                id
                childItems {
                  nodes {
                    label
                    id
                    path
                  }
                }
              }
            }
          }
        }
        allWpCategory(
          filter: { details_sections: { isMenuItem: { eq: true } } }
        ) {
          nodes {
            id
            slug
            nodeType
            label: name
            count
            details_sections {
              categoryOrder
              sections {
                ... on WpCategory_DetailsSections_Sections_BannerSection {
                  cardsList {
                    selectPost {
                      ... on WpPost {
                        id
                        uri
                        slug
                        label: title
                        post {
                          articleOrder
                        }
                      }
                    }
                    cardType
                  }
                }
              }
            }
            childItems: posts {
              nodes {
                id
                slug
                label: title
                post {
                  articleOrder
                }
              }
            }
          }
        }
        allWp {
          nodes {
            footer {
              footer {
                addonMenu {
                  selectArticleNameAndUrl {
                    label
                    url
                  }
                  selectDepartment {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const compare = (a, b) => {
    if (a?.post?.articleOrder < b?.post?.articleOrder) {
      return -1
    }
    if (a?.post?.articleOrder > b?.post?.articleOrder) {
      return 1
    }
    return 0
  }

  const compareCategory = (a, b) => {
    if (
      a?.details_sections?.categoryOrder < b?.details_sections?.categoryOrder
    ) {
      return -1
    }
    if (
      a?.details_sections?.categoryOrder > b?.details_sections?.categoryOrder
    ) {
      return 1
    }
    return 0
  }

  const { menuItems } = nodes[0]

  const loadNodes = node => {
    const { sections } = node.details_sections
    const { cardsList } = sections[0]
    return (cardsList || [])
      .map(card => {
        if (card.cardType === "post_card") {
          return {
            ...card.selectPost,
          }
        }
      })
      .filter(Boolean)
  }

  const catMenuItems = catNodes.sort(compareCategory).map(node => {
    node.path = `/${node.slug}`
    let childNodes = loadNodes(node)
    node.childItems.nodes = (childNodes || []).sort(compare)
    return node
  })

  const { footer } = footerNodes[0] || {}
  const {
    footer: { addonMenu },
  } = footer || { footer: {} }

  let unused = (addonMenu || []).map(addOn => {
    if (addOn?.selectDepartment?.id) {
      const menu = catMenuItems.find(
        catMenu => catMenu.id === addOn?.selectDepartment?.id
      )
      if (!menu) {
        return
      }
      const subMenus = (addOn?.selectArticleNameAndUrl || []).map(article => {
        return {
          ...article,
          slug: article.url,
          id: article.label.split(" ").join("-"),
        }
      })
      const subMenuItem = [...menu.childItems.nodes, ...subMenus]
      menu.childItems.nodes = [
        ...new Map(subMenuItem.map(item => [item.id, item])).values(),
      ]
    }
  })

  const parentMenuItems = [...catMenuItems, ...(menuItems?.nodes || [])]

  const [selectedMenu, setSelectedMenu] = React.useState(0)

  return (
    <>
      <div className="navbar-content" id="site-menu">
        <div className="navbar-content__inner">
          <div className="navbar-heading">
            <div className="label">{constants.landingTitle}</div>
            <div className="year"> {constants.landingYear}</div>
          </div>
          <ul className="nav-primary">
            {(parentMenuItems || []).map((menuItem, index) => {
              if (!menuItem.count) {
                return (
                  <RenderMenu
                    key={menuItem.id}
                    menuItem={menuItem}
                    index={index}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                  />
                )
              } else {
                return (
                  <RenderCollapsibleMenu
                    key={menuItem.id}
                    menuItem={menuItem}
                    index={index}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                  />
                )
              }
            })}
          </ul>
        </div>
      </div>
      <div className="svg-container">
        <div className="tooltipInner">
          <svg className="circle" xmlns="http://www.w3.org/2000/svg">
            <g>
              <ellipse
                className="foreground"
                ry="38"
                rx="38"
                cy="40"
                cx="40"
                strokeWidth="2"
              />
            </g>
          </svg>
          <div className="tooltipText">
            <span></span>
          </div>
        </div>
      </div>
    </>
  )
}
