import { Link } from "gatsby"
import gsap from "gsap"
import React from "react"
import logo from "../../assets/images/hss-logo.svg"
import { Menu } from "../menu/menu"
import "./Layout.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import LandScapeWarning from "../Warning/Warning"

export const SubMenu = params => {
  return (
    <ul className="nav-secondary">
      {params.menuItems &&
        params.menuItems.map(menu => {
          return (
            <li key={menu.id}>
              <Link to={menu.path}>{menu.label}</Link>
            </li>
          )
        })}
    </ul>
  )
}

export function Layout({ children, isArticle}) {
  const navWrapperRef = React.useRef()
  const [menuControlLabel, setMenuControlLabel] = React.useState("menu")

  const onToggleClick = () => {
    if (
      navWrapperRef.current &&
      !navWrapperRef.current.classList.contains("is--open")
    ) {
      gsap.to(".navbar-content", {
        x: 0,
        duration: 1,
        ease: "power4.out",
      })
      gsap.from(".nav-primary > li > a", {
        x: "-50px",
        duration: 0.4,
        ease: "power4.out",
        delay: 0.1,
        opacity: 0.7,
      })
      gsap.fromTo(
        ".nav-secondary",
        {
          duration: 0.8,
          ease: "sin.out",
          delay: 0.4,
          opacity: 0.1,
        },
        {
          duration: 0.4,
          ease: "sin.out",
          delay: 0.4,
          opacity: 1,
        }
      )
      setMenuControlLabel("close")
      gsap.to(".navbar-control .icon", {
        rotate: "-180deg",
        duration: 0.4,
      })
      navWrapperRef.current.classList.add("is--open")
    } else {
      gsap.fromTo(
        ".navbar-content",
        {
          x: 0,
          duration: 0.8,
          ease: "power4.out",
        },
        {
          x: "-100%",
          duration: 1,
          ease: "power4.out",
          delay: 0.2,
        }
      )
      gsap.fromTo(
        ".navbar-control .icon",
        {
          rotate: "-180deg",
          duration: 0.4,
        },
        {
          rotate: "0deg",
          duration: 0.4,
        }
      )
      gsap.fromTo(
        ".nav-secondary",
        {
          duration: 0.4,
          ease: "sin.out",
          delay: 0.4,
          opacity: 1,
        },
        {
          duration: 0.3,
          ease: "sin.out",
          opacity: 0.1,
        }
      )
      navWrapperRef.current.classList.remove("is--open")
      setMenuControlLabel("menu")
    }
  }

  return (
    <>
      <div className="screen-warning IEWarning p-5">
        <div className="text-1">
          For the optimal viewing experience, please view the HSS Annual report
          with Microsoft Edge, Google Chrome, Mozilla Firefox or Opera.
        </div>
      </div>
      <nav
        className={!!isArticle ? "navbar-vertical backBtn" : "navbar-vertical"}
        ref={navWrapperRef}
      >
        <div className="navbar-inner">
          <AniLink to="/" cover bg="#1aafe5" className="brand-logo">
            <img src={logo} alt="HSS logo" className="img-fluid" />
          </AniLink>
          <a
            className="btn-icon btn-close btn-primary navBackBtn"
            bg="#1aafe5"
            href="/"
          >
            <svg
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
              className="caret-left"
            >
              <path d="M11 4 L6 9 11 14 12 13 8 9 12 5 Z"></path>
            </svg>
          </a>
          <div className="navbar-footer">
            <button className="navbar-control btn-icon" onClick={onToggleClick}>
              <span className="icon">
                <svg
                  className="brand-color"
                  enableBackground="new 0 0 15 26"
                  height="26px"
                  id="Layer_1"
                  version="1.1"
                  viewBox="0 0 15 26"
                  width="15px"
                  xmlns="http://www.w3.org/2000/svg"
                  // xml:space="preserve"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <polygon points="2.019,0.58 -0.035,2.634 10.646,13.316 -0.035,23.997 2.019,26.052 14.755,13.316 " />
                </svg>
              </span>
              <span className="label">{menuControlLabel}</span>
            </button>
          </div>
        </div>
        <Menu
          closeMenu={onToggleClick}
        />
      </nav>
      <>{children}</>
      <LandScapeWarning />
      {
        <script>
          if (typeof window !== 'undefined') ? window.document &&
          window.document.querySelector("body").classList.add("page-loaded"):
          null
        </script>
      }
    </>
  )
}
